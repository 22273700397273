import React, { Suspense, lazy } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import WOW from "wowjs";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import "./toastr.css";

import { ReactComponent as LoaderIcon } from "./img/svg/loader.svg";
const AstraLander = lazy(() => import("./Components/AstraLander"));
const Error404 = lazy(() => import("./Components/ErrorPages/error404"));

// Animation Wow
new WOW.WOW({
  live: false,
}).init();

function App() {
  return (
    <div className="App overflow-hidden">
      <HashRouter>
        <Suspense
          fallback={
            <div className="page-loader">
              <LoaderIcon />
            </div>
          }
        >
          <Switch>
            <Route exact path={`/`} render={(props) => <AstraLander />} />
            <Route path="*" render={(props) => <Error404 />} />
          </Switch>
        </Suspense>
      </HashRouter>
    </div>
  );
}

export default App;
